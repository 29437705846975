import React, { useEffect, useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  Button,
  H4,
  FieldBoolean,
  FieldCheckbox,
} from '../../../components';
import css from './SignupForm.module.css';
import logoEcym from '../../../assets/logo-tecmilenioxecym.svg';

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
      } = fieldRenderProps;

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const privacidadCheck = validators.requireTermsAndConditions('message');

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const [typeUser, setTypeUser] = useState('coach');

      if (typeof window !== 'undefined') {
        if (!localStorage.getItem('typeuser')) {
          localStorage.setItem('typeuser', 'coach');
        }
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div style={{ alignItems: 'center' }}>
            {/*<H4
              style={{
                color: '#00534C',
                fontSize: '40px',
                textAlign: 'center',
                marginBottom: '27px',
              }}
            >
              Crear cuenta
            </H4>
            */}

            <div
              style={{
                width: '406px',
                textAlign: 'center',
                color: 'black',
                fontSize: '32px',
                fontWeight: '600',
                lineHeight: '32px',
                letterSpacing: '0.32px',
                wordWrap: 'break-word',
                marginBottom:'24px',
                marginTop:'34px'
              }}
            >
              Crear cuenta
            </div>

            <div
              style={{
                width: '100%',
                height: '39px',
                display: 'inline-flex',
                flexDirection: 'row',
                marginBottom: '52px',
                justifyContent: 'center',
              }}
            >
              <button
                className={typeUser === 'coach' ? css.buttonSelected : css.button}
                id="typeuser"
                name="typeuser"
                type="button"
                onClick={() => {
                  if (typeof window !== 'undefined') {
                    localStorage.setItem('typeuser', 'cliente');
                  }
                  setTypeUser('cliente');
                }}
              >
                <FormattedMessage id="SignupForm.client" />
              </button>
              <button
                className={typeUser === 'cliente' ? css.buttonSelected : css.button}
                id="typeuser"
                name="typeuser"
                type="button"
                onClick={() => {
                  if (typeof window !== 'undefined') {
                    localStorage.setItem('typeuser', 'coach');
                  }
                  setTypeUser('coach');
                }}
              >
                <FormattedMessage id="SignupForm.coach" />
              </button>
            </div>

            <FieldTextInput
              className={css.firstNameRoot}
              type="text"
              id={formId ? `${formId}.fname` : 'fname'}
              name="fname"
              autoComplete="given-name"
              label={intl.formatMessage({
                id: 'SignupForm.firstNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.firstNamePlaceholder',
              })}
              validate={validators.required(
                intl.formatMessage({
                  id: 'SignupForm.firstNameRequired',
                })
              )}
            />

            <div className={css.name}>
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lFname` : 'lFname'}
                name="lFname"
                autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'SignupForm.firstLastNamePlaceholder',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.firstLastNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.lastNameRequired',
                  })
                )}
              />

              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lSname` : 'lSname'}
                name="lSname"
                autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'SignupForm.secondLastNamePlaceholder',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.secondLastNamePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.lastNameRequired',
                  })
                )}
              />
            </div>

            <FieldTextInput
              style={{ marginBottom: '16px' }}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={intl.formatMessage({
                id: 'SignupForm.emailLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.emailPlaceholder',
              })}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />

            <FieldTextInput
              id="validaEmail"
              type="email"
              name="validaEmail"
              autoComplete="email"
              label="Confirma correo electrónico"
              placeholder={intl.formatMessage({
                id: 'SignupForm.emailConfirmPlaceHolder',
              })}
              validate={
                typeof window !== 'undefined' &&
                validators.validateEmail(
                  'Los correos no son iguales',
                  localStorage.getItem('email')
                )
              }
            />

            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={intl.formatMessage({
                id: 'SignupForm.passwordLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
            />
          </div>

          <div className={css.bottomWrapper}>
            <div className={css.termsnConditionsWrapper}>
              <FieldCheckbox
                className={css.checkbox}
                id="checkbox-id1"
                name="agreePrivacyPolicy"
                label=""
                validate={privacidadCheck}
              />
              <div>
                Acepto la <a href="/privacy-policy">Política de privacidad</a>
              </div>
            </div>
            <PrimaryButton
              className={css.PrimaryButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
